import request from '@/utils/request'

// 优惠券添加、编辑提交
export function subCouponsForm (data) {
    return request({
        url: '/customer-service/lrCoupon/saveLrCoupon',
        method: 'post',
        data
    })
}

// 优惠券添加、编辑提交
export function subCouponsFormOld (data) {
    return request({
        url: '/customer-service/lrCoupon/saveLrCouponOld',
        method: 'post',
        data
    })
}

// 获取优惠券品类   不分页
export function getCouponClassify () {
    return request({
        url: "/customer-service/lrCouponClassify/queryCouponClassify",
        method: 'post',
        data : {
            pageSize: 999,
            pageNum: 1
        }
    })
}

// 删除优惠券品类
export function deleteCouponClassify (id) {
    return request({
        url: "/customer-service/lrCouponClassify/delCouponClassify?id=" + id,
        method: 'delete'
    })
}

// 获取优惠券分类列表
export function getCouponClassifyList (data) {
    return request({
        url: "/customer-service/lrCouponClassify/queryCouponClassify",
        method: 'post',
        data
    })
}

// 添加、编辑优惠券品类
export function addCouponClassify (data) {
    return request({
        url: "/customer-service/lrCouponClassify/addOrUpdateCouponClassify",
        method: 'post',
        data
    })
}

// 获取所有优惠券列表
export function getCouponList (data) {
    return request({
        // url: "/customer-service/lrCoupon/queryCouponList",
        url: "/customer-service/lrCoupon/queryActivityCouponList",
        method: 'post',
        data
    })
}

//获取所有优惠券列表（旧）
export function getCouponListOld (data) {
    return request({
        url: "/customer-service/lrCoupon/queryCouponList",
        method: 'post',
        data
    })
}

// 优惠券上下架
export function upDownShelfCoupon (id, status) { //type 1 上架 0 下架
    return request({
        url: "/customer-service/lrCoupon/upAndDownCoupon?id=" +id + "&status=" + status,
        method: 'post'
    })
}

// 删除优惠券
export function deleteCoupon (id) {
    return request({
        url: "/customer-service/lrCoupon/delLrCoupon?id=" + id,
        method: 'delete'
    })
}

// 根据id获取优惠券详情
// export function getCouponDetail (id) {
//     return request({
//         url: "/customer-service/lrCoupon/couponDetail?couponId=" + id,
//         method: 'get'
//     })
// }

// 根据id获取优惠券详情
export function queryAllClassifyGoods (keyword,goodsType) {
    return request({
        url: "/shd-operation/lrGoodsClassify/queryAllClassifyGoods?keyword=" + keyword + "&goodsType=" + goodsType,
        method: 'get'
    })
}

// 复制优惠券
export function copyLrCoupon (data) {
    return request({
        url: "/customer-service/lrCoupon/copyLrCoupon",
        method: 'post',
        data
    })
}

// 优惠券数据
export function queryRelationCoupon (data) {
    return request({
        url: "/customer-service/lrCoupon/queryRelationCoupon",
        method: 'post',
        data
    })
}
// 导出优惠券数据
export function exportCoupon (data) {
    return request({
        url: "/customer-service/lrCoupon/exportRelationCoupon",
        method: 'get',
        params:data
    })
}
// 根据ID获取优惠详情
export function getCouponDetail (id) {
    return request({
        url: "/customer-service/lrCoupon/couponBaseDetail?couponId=" + id,
        method: 'get'
    })
}
// 根据ID获取优惠券关联商品
export function getGoodsByCouponId (data) {
    return request({
        url: `/customer-service/lrCoupon/goodsByCouponId?couponId=${data.couponId}&pageSize=${data.pageSize}&pageNum=${data.pageNum}&name=${data.name}&storeId=${data.storeId}&goodsCode=${data.goodsCode}`,
        method: 'get'
    })
}
//批量删除优惠券关联商品
export function delCouponGoodsByIds (data) {
    return request({
        url: "/customer-service/lrCoupon/delCouponGoodsByIds",
        method: 'post',
        data
    })
}
// 保存或者更新优惠券关联商品
export function saveGoodsCouponRelation (data) {
    return request({
        url: "/customer-service/lrCoupon/saveGoodsCouponRelation",
        method: 'post',
        data
    })
}
// 5.3查询商品列表，商超和百货
export function queryGoodsByGoodsType (data) {
    return request({
        url: "/shd-operation/lrGoodsInfo/queryGoodsByGoodsType",
        method: 'post',
        data
    })
}
// 5.2查询分类
export function queryClassifyTreeByGoodsType (data) {
    return request({
        url: "/shd-operation/lrGoodsInfo/queryClassifyTreeByGoodsType",
        method: 'post',
        data
    })
}
// 1.9复制商品
export function copyLrCouponRelation (data) {
    return request({
        url: `/customer-service/lrCoupon/copyLrCouponRelation?oldCouponId=${data.oldCouponId}&newCouponId=${data.newCouponId}`,
        method: 'get',
    })
}
