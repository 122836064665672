<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="搜索关键词" class="search-input" v-model="searchObj.searchKey"></el-input>
          <!-- <el-select placeholder="适用品类" class="search-input ml-15" v-model="searchObj.type">
            <el-option
              v-for="(item,index) in cou_type"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>-->
          <el-select placeholder="选择状态" class="search-input ml-15" v-model="searchObj.status">
            <el-option
              v-for="(item,index) in cou_status"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="initData">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link :to="{name:'Coupons_new_old', params: {addFlag: true}}">
                <el-button class="ml-15" type="primary">创建优惠券</el-button>
              </router-link>
            </div>
            <!-- <div class="flx-row ali-c">
              <el-button icon="el-icon-download" type="primary" plain>导入</el-button>
              <el-button icon="el-icon-upload2" class="ml-15" type="primary" plain>导出</el-button>
            </div>-->
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData" @selection-change="tableSelect">
            <el-table-column label="优惠券名称" prop="name"></el-table-column>
            <el-table-column label="优惠券类型">
              <template slot-scope="scope">
                {{getCouponTypeStr(scope.row.coupon)}}
              </template>
            </el-table-column>
             <el-table-column label="适用品类" prop="couponClassifyName"></el-table-column>
            <el-table-column label="卡券有效期" prop='validTime'></el-table-column>
            <el-table-column label="领取条件">
              <template slot-scope="scope">
                {{scope.row.drawCondition == 1 ? "免费领取" : "限领"}}
              </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="stock"></el-table-column>
            <el-table-column label="每人限领" prop="limitAmount"></el-table-column>
            <el-table-column label="领取数量" prop="received"></el-table-column>
            <el-table-column label="状态" width="200px">
              <template slot-scope="scope">            
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"                
                  @change="upDownShelf($event, scope.row.couponId)"
                ></el-switch>
               
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{name: 'Coupons_new_old', query: {addFlag: false, id:scope.row.couponId}}"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                  </router-link>
                  <el-link
                    type="danger"
                    :underline="false"
                    class="ml-10"
                    @click="deleteCoup(scope.row.couponId)"
                  >
                    <i class="el-icon-delete-solid"></i>
                    删除
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="initData"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {getCouponClassify, getCouponListOld, upDownShelfCoupon, deleteCoupon } from "@/api/maketing/coupons"
export default {
  name: "members_list",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        searchKey: "",
        status: "",
      },
      // 优惠券状态列表,用于筛选
      cou_status: [
        { id: 1, name: "上架" },
        { id: 0, name: "下架" }
      ],
      // 优惠券使用品类列表,用于筛选
      // 优惠券表格数据内容
      tableData: [],
      createUser: "",
      addFlag: true,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      couponType:[
        {
          type:1,
          name:"通用券"
        },
          {
          type:2,
          name:"商品劵"
        },
          {
          type:3,
          name:"餐饮优惠券"
        },
          {
          type:4,
          name:"折扣券"
        },
          {
          type:5,
          name:"代金券"
        },
          {
          type:6,
          name:"停车券"
        },
      ]
    };
  },
  mounted() {
    this.initData(1);
  },
  methods: {
    //获取优惠券品类
    getType() {
      getCouponClassify().then(res => {
        this.cou_type = res.data.body.list;
      })
    },
    //获取优惠券类型的string
    getCouponTypeStr(id) {
      let name = ''
      this.couponType.forEach(item => {
        if(item.type == id) {
          name = item.name
          return
        }
      })
      return name
    },
    //获取所有优惠券
    initData(type) {
      if(type == 1){
          this.pageNum = 1
          this.pageSize = 10
      }
      let data = {
        isOld: 0,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchObj
      };

      getCouponListOld(data).then(res => {
        // // console.log(res);
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
        }
      })
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        searchKey: "",
        status: null,
      };
      this.initData(1);
    },
    //上下架
    upDownShelf(status, id) {
       console.log(status, id);
      upDownShelfCoupon(id,status).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      }).catch(err=>{
          this.initData();
      })
    },

    //删除优惠券
    deleteCoup(id) {
      this.$confirm("此操作将永久删除该优惠券, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteCoupon(id).then(res => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success"
              });
              this.initData();
            }
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
